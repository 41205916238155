<template>
  <div class="report-component-wrapper" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item - 1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs color="grey darken-2">
                <v-tab v-if="!flat.id">Друкована форма</v-tab>
                <v-tab>Відбір</v-tab>
                <v-tab v-if="!flat.id">Фільтр</v-tab>

                <v-tab-item class="mt-3" v-if="!flat.id">
                  <v-row>
                    <v-col cols="12" class="pb-4">
                      <SpreedSheetSelect :value="filters.spreed_sheet_id" :required="true"
                                         :disabled="flat.id"
                                         @autocompleteChange="onSpreedSheetChange"/>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <template v-if="flat.id">
                    <v-row class="dense-wrapper pb-1 pt-2">
                      <v-col cols="12" md="12">
                        <v-select
                            hide-details
                            :items="services"
                            v-model="filters.service_id"
                            :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="filters.service_id ? '' : 'req-star'"
                            color="grey"
                            filled
                            label="Послуга" clearable
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-select :items="indicators_select" hide-details filled label="Вид показника"
                                  v-model="filters.indicator_id"
                                  required
                                  :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="filters.indicator_id ? '' : 'req-star'"
                                  clearable color="grey"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row class="dense-wrapper pb-1 pt-2">
                      <v-col cols="12" md="6">
                        <v-select
                            hide-details
                            :items="services"
                            v-model="filters.service_id"
                            :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="filters.service_id ? '' : 'req-star'"
                            color="grey"
                            filled
                            label="Послуга" clearable
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select :items="indicators_select" hide-details filled label="Вид показника"
                                  v-model="filters.indicator_id"
                                  required
                                  :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="filters.indicator_id ? '' : 'req-star'"
                                  clearable color="grey"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <AddressElementSelect
                            label="Об’єднана громада" v-model="filters.amalgamated_hromada_id"
                            filled select_type="amalgamated_hromada"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <AddressElementSelect
                            label="Населений пункт" v-model="filters.city_id"
                            :parent_id="filters.amalgamated_hromada_id" :use_parent_id="true"
                            filled select_type="city"
                        />
                      </v-col>
                      <v-col cols="12">
                        <AddressElementSelect
                            label="Район міста" v-model="filters.city_area_id"
                            :parent_id="filters.city_id" :use_parent_id="true"
                            filled select_type="city-area"
                        />
                      </v-col>
                      <v-col cols="12" v-if="filters.city_area_id">
                        <AddressElementSelect
                            label="Вулиця" v-model="filters.streets" multiple
                            :parent_id="filters.city_area_id" :use_parent_id="true"
                            filled select_type="street-with-city-area"
                        />
                      </v-col>
                      <v-col cols="12" v-else>
                        <AddressElementSelect
                            label="Вулиця(-ці)" v-model="filters.streets" multiple
                            :parent_id="filters.city_id" :use_parent_id="true"
                            filled select_type="street"
                        />
                      </v-col>
                      <v-col cols="12">
                        <AddressElementSelect
                            label="Будинок(-ки)" v-model="filters.buildings" multiple
                            :parent_id="filters.streets" :use_parent_id="true"
                            filled select_type="building"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select hide-details filled v-model="filters.building_type"
                                  :items="buildingTypes_select" label="Вид житла"
                                  clearable color="grey"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <Checker :value="filters.checker_id" @autocompleteChange="onCheckerChange"/>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select hide-details filled v-model="filters.disable_filter"
                                  :items="flat_status_select" label="Стан абонента"
                                  clearable color="grey"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-switch
                            v-model="filters.charge_and_balances_by_service"
                            inset
                            hide-details
                            color="success"
                            class="mt-0 ml-2"
                            :label="filters.charge_and_balances_by_service ? 'Залишки та нарахування по послузі (включено)' : 'Залишки та нарахування по послузі (виключено)'"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="dense-wrapper pb-1 pt-2">
                      <v-col cols="12" md="12">
                        <v-switch
                            v-model="filters.non_pays_filter"
                            inset
                            hide-details
                            color="success"
                            class="mt-0 ml-2"
                            :label="filters.non_pays_filter ? 'Відбір по неоплаті (включений)' : 'Відбір по неоплаті (виключений)'"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <date-component v-model="filters.non_pays_start" filled :req="filters.non_pays_filter"
                                        :disabled="!filters.non_pays_filter" label="Період оплати з"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <date-component v-model="filters.non_pays_end" filled :req="filters.non_pays_filter"
                                        :disabled="!filters.non_pays_filter" label="Період оплати по"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="dense-wrapper pb-1 pt-2">
                      <v-col cols="12" md="12">
                        <v-switch
                            v-model="filters.contract_date_filter"
                            inset
                            hide-details
                            color="success"
                            class="mt-0 ml-2"
                            :label="filters.contract_date_filter ? 'Відбір по даті договору (включений)' : 'Відбір по даті договору (виключений)'"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <date-component v-model="filters.contract_date_filter_start" filled :req="filters.contract_date_filter"
                                        :disabled="!filters.contract_date_filter" label="Дата договору з"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <date-component v-model="filters.contract_date_filter_end" filled :req="filters.contract_date_filter"
                                        :disabled="!filters.contract_date_filter" label="Дата договору по"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="dense-wrapper pb-1 pt-2">
                      <v-col cols="12" md="4">
                        <v-switch
                            v-model="filters.without_items"
                            inset
                            hide-details
                            color="success"
                            class="mt-0 ml-2"
                            :label="filters.without_items ? 'Без абонентів (включено)' : 'Без абонентів (виключено)'"
                            @change="onFullWidthChange"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-switch
                            v-model="filters.only_reminder"
                            inset
                            hide-details
                            color="success"
                            class="mt-0 ml-2"
                            :label="filters.only_reminder ? 'Остача об’єму (включено)' : 'Остача об’єму (виключено)'"
                            @change="onFullWidthChange"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-switch
                            v-model="filters.auto_width"
                            inset
                            hide-details
                            color="success"
                            class="mt-0 ml-2"
                            :label="filters.auto_width ? 'По ширині сторінки (включений)' : 'По ширині сторінки (виключений)'"
                            @change="onFullWidthChange"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-tab-item>
                <v-tab-item class="mt-3" v-if="!flat.id">
                  <v-row class="dense-wrapper pb-1 pt-2">
                    <v-col cols="12">
                      <v-card
                          v-for="(item, idx) in post_filter"
                          :key="`gr-idx-${idx}`"
                          style="display: flex; align-items: center"
                          class="ma-2 py-2 post-filter"
                          elevation="3"
                      >
                        <div style="flex: 0 0 60px">
                          <v-checkbox color="success" hide-details
                                      class="ml-3 mt-0 pt-0" v-model="item.active" :ripple="false"/>
                        </div>
                        <div style="flex: 1; font-weight: 400; font-size: .86rem">
                          {{ item.name }}
                        </div>
                        <div style="flex: 0 0 130px; font-weight: 400; font-size: .86rem">
                          <v-select :items="operator_select" hide-details single-line
                                    style="font-size: .86rem; text-align: center;" dense
                                    class="mt-0 table-small-select text-center"
                                    color="success"
                                    v-model="item.operator"
                                    required
                                    :class="{'input-error': !item.operator}"
                                    :rules="[v => !!v || 'Це поле є обов’язковим']"/>
                        </div>
                        <div style="flex: 0 0 120px; font-weight: 400; font-size: .86rem">
                          <input v-model.number="item.value_from"
                                 v-decimal class="text"
                                 :class="{'input-error': item.value_from === null || item.value_from === undefined}"/>
                        </div>
                        <div style="flex: 0 0 120px; font-weight: 400; font-size: .86rem"
                             v-show="item.operator === 'between'">
                          <input v-model.number="item.value_to"
                                 v-decimal class="text-center"
                                 :class="{'input-error': item.value_to === null || item.value_to === undefined}"/>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-dialog persistent fullscreen v-if="show_dialog" v-model="show_dialog">
      <HousesPayWorkPlace :modal_item="true"
                          :modal_person_hash="report_row.person_hash"
                          @closeWorkPlaceModalItem="closeWorkPlaceModalItem"
      />
    </v-dialog>
    <v-menu
        v-model="show_report_menu"
        :position-x="x1"
        :position-y="y1"
        absolute
        offset-y
    >
      <v-list dense nav>
        <v-subheader>
          {{ report_row?.address || '' }}
        </v-subheader>
        <v-divider></v-divider>
        <v-list-item dense style="font-size: .8rem"
                     @click="openWorkPlace"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="teal" size="20">
              mdi-account-search
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Відкрити картку абонента
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="pages" id="print">
      <div class="page page-for-print"
           style="padding: 0 !important;"
           :class="{
                    'page-a4-landscape-auto': page_size === 'landscape',
                    'page-a4': page_size === 'letter',
                    'page-separator': items.length > 0
                   }"
           v-for="(page, index) in items" :key="`page-${index}`">
          <table class="custom"
                 style="border: none"
                 :style="`width: ${table_width - 50 || 780}px`"
                 v-for="(item, item_index) in page"
                 :key="`table-${index}-${item_index}`">
            <colgroup>
              <col :width="col.width" v-for="col in col_group.slice(1)" :key="col.id"/>
            </colgroup>
            <tbody :key="item.index"
                   class="mt-12 custom"
            >
            <tr class="custom" v-for="row in item" :key="row.id">
              <td class="custom" v-for="col in row.slice(1)" :key="col.id" :style="col.col_style ? `${col.col_style}; ${col.style}` : col.style"
                  :rowspan="col.rowspan" :colspan="col.colspan"
                  :height="col.col_height"
                  style="white-space: pre-wrap"
                  :class="{'table-page-separator': col.textContent === '@page_separator'}"
              >
                <template v-if="col.textContent !== '@page_separator'">
                  {{ col.textContent }}
                </template>
                <template v-if="col.image_type && col.image_url">
                  <div
                      class="td-image-wrapper"
                      :style="
                          `
                          height: ${col.image_height}${col.image_height > 0 ? 'px' : ''};
                          width: ${col.image_width}${col.image_width > 0 ? 'px' : ''};
                          `
                          "
                  >
                    <div class="td-image"
                         :style="
                          `
                          position: absolute;
                          top: ${col.image_position_top}${col.image_position_top > 0 ? 'px' : ''};
                          left: ${col.image_position_left}${col.image_position_left > 0 ? 'px' : ''};
                          height: ${col.image_height}${col.image_height > 0 ? 'px' : ''};
                          width: ${col.image_width}${col.image_width > 0 ? 'px' : ''};
                          `
                          "
                    >
                      <img :src="`${BASE_URL_AXIOS}/${col.image_url}`"
                           alt="qr code"
                           style="object-fit: fill; height: 100%; width: 100%">
                    </div>
                  </div>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>
</template>

<script>

import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import ReportHouseAccountingComponentMixin from "@/mixins/report_house_accounting";
import {mapGetters} from "vuex";
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";
import {buildingTypes_select} from "@/utils/icons"
import {BASE_URL_AXIOS} from "@/utils/axios";

export default {
  name: "universal_spreed_sheet_report_by_flat",
  props: ['flat', 'payload_add_int_1'],
  mixins: [ReportHouseAccountingComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    SpreedSheetSelect: () => import("@/components/autocomplite/SpreedSheet"),
    Checker: () => import('@/components/autocomplite/Checker'),
    HousesPayWorkPlace: () => import("@/components/HousePayWorkPlace.vue"),
  },
  computed: {
    ...mapGetters(
        {
          services: 'getServicesSelectWithoutAll',
          indicators_select: 'getFlatIndicatorTypes',
          settings: 'getGeneralSettings'
        }
    )
  },
  data() {
    return {
      drag: false,
      filters: {
        checker_id: null,
        spreed_sheet_id: null,
        amalgamated_hromada_id: null,
        city_id: null,
        city_area_id: null,
        streets: [],
        buildings: [],
        building_type: null,
        disable_filter: 'all',
        service_id: null,
        indicator_id: null,
        non_pays_filter: false,
        non_pays_start: null,
        non_pays_end: null,
        charge_and_balances_by_service: false,
        contract_date_filter: false,
        contract_date_filter_start: null,
        contract_date_filter_end: null,
        auto_width: true,
        without_items: false,
        only_reminder: false
      },
      operator_select: [
        { text: 'Дорівнює', value: '=' },
        { text: 'Не дорівнює', value: '!=' },
        { text: 'Більше рівне', value: '>=' },
        { text: 'Менше рівне', value: '<=' },
        { text: 'Більше', value: '>' },
        { text: 'Менше', value: '<' },
        { text: 'Між', value: 'between' },
      ],
      post_filter: [
        { active: false, name: 'Тариф', column: 'tariff', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Норм. показ.', column: 'indicator_value', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'К-сть договорів', column: 'contract_count', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Сальдо на поч.', column: 'start_balance', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Сальдо на поч. Дт', column: 'start_balance_plus', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Сальдо на поч. Кт', column: 'start_balance_minus',operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Нараховано', column: 'charge', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Знято', column: 'removal', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Перераховано', column: 'recalculation', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Оплачено', column: 'pay', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Сальдо на кін.', column: 'end_balance', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Сальдо на кін. Дт', column: 'end_balance_plus', operator: '=',  value_from: 0, value_to: 0},
        { active: false, name: 'Сальдо на кін. Кт', column: 'end_balance_minus', operator: '=',  value_from: 0, value_to: 0},
      ],
      buildingTypes_select,
      flat_status_select: [
        {text: 'Усі', value: 'all'},
        {text: 'Актуальні', value: 'active'},
        {text: 'Закриті', value: 'closed'},
      ],
      report_settings: {},
      x1: 0,
      y1: 0,
      show_report_menu: false,
      report_row: null,
      show_dialog: false,
      col_group: [],
      page_size: 'letter',
      payload_int_watcher: null,
      BASE_URL_AXIOS,
      table_width: 0
    }
  },
  methods: {
    openWorkPlace() {
      this.show_dialog = true
    },
    closeWorkPlaceModalItem() {
      this.show_dialog = false
      this.report_row = null
    },
    onCheckerChange(payload) {
      this.filters.checker_id = (payload || {}).value || null
    },
    onSpreedSheetChange(payload) {
      this.filters.spreed_sheet_id = (payload || {}).value || null

      this.$nextTick(() => {
        if (this.filters.spreed_sheet_id && !this.flat_id) {
          reportAPI.report_settings("universal_spreed_sheet_report_by_flat", this.filters.spreed_sheet_id)
              .then(response => response.data)
              .then(data => {
                this.page_size = (payload || {}).type || null
                const server_filters = JSON.parse(data)
                Object.keys(this.filters).forEach(key => {
                  if (server_filters[key] !== undefined) {
                    if (key !== 'spreed_sheet_id') {
                      this.filters[key] = server_filters[key]
                    }
                  }
                })
              })
        }
      })
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end && this.filters.service_id && this.filters.indicator_id && this.filters.spreed_sheet_id) {
        this.$emit('xlsx_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {post_filter: this.post_filter}),
        }
        if (this.flat) {
          payload.filters.flat_id = this.flat.id
        }
        reportAPI.universal_spreed_sheet_report_by_flat_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `spreed_sheet_${this.formatDate(this.date_start, 'MM_YYYY')}_${this.formatDate(this.date_end, 'MM_YYYY')}_${this.filters.spreed_sheet_id}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      } else {
        this.$store.commit(ALERT_SHOW, {
          text: 'Період, друкована форма, послуга та нормативний показник - мають бути заповнений',
          color: 'error'
        })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end && this.filters.service_id && this.filters.indicator_id && this.filters.spreed_sheet_id) {
        this.$emit('email_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          email: this.email_address,
          filters: Object.assign({}, this.filters, {post_filter: this.post_filter}),
        }
        if (this.flat) {
          payload.filters.flat_id = this.flat.id
        }
        reportAPI.universal_spreed_sheet_report_by_flat_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      } else {
        this.$store.commit(ALERT_SHOW, {
          text: 'Період, друкована форма, послуга та нормативний показник - мають бути заповнений',
          color: 'error'
        })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end && this.filters.service_id && this.filters.indicator_id && this.filters.spreed_sheet_id) {
        this.$emit('report_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {post_filter: this.post_filter}),
        }
        if (this.flat) {
          payload.filters.flat_id = this.flat.id
        }
        reportAPI.universal_spreed_sheet_report_by_flat(payload)
            .then(response => response.data)
            .then(data => {
              this.expand = false
              this.by_grouping = false
              this.max_lvl = 0

              this.items = data.data
              this.col_group = data.col_group

              this.table_width = data.col_group.reduce((acc, i) => {
                return acc + parseInt(i.width)
              }, 0)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(this.max_lvl)
                // this.$nextTick(() => {
                this.getOnFullPage()
                // })
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {
          text: 'Період, друкована форма, послуга та нормативний показник - мають бути заповнений',
          color: 'error'
        })
      }
    },
    getOnFullPage() {
    },
    onFullWidthChange(payload) {
      this.$nextTick(() => {
        if (payload) {
          this.getOnFullPage()
        }
      })
    },
    watch_payload_int() {
      this.payload_int_watcher = this.$watch(
          'payload_add_int_1',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.filters.spreed_sheet_id = payload

                if (this.date_start && this.date_end && this.filters.service_id && this.filters.indicator_id && this.filters.spreed_sheet_id) {
                  this.generate_report()
                }
              }
            }
          }
      )
    },
  },
  created() {
    this.$store.dispatch(FETCH_FLAT_INDICATOR_TYPES)
        .then(() => {
          this.$nextTick(() => {
            if (!this.filters.indicator_id) {
              this.filters.indicator_id = this.settings?.general_indicator?.value || null
            }
            if (!this.filters.service_id) {
              this.filters.service_id = this.settings?.default_service?.value || null
            }

            this.watch_payload_int()

            this.getOnFullPage()
          })
        })
  },
  beforeDestroy() {
    if (this.payload_int_watcher) {
      this.payload_int_watcher()
    }
  }
}
</script>

<style lang="scss" scoped>
.field-card {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;

  &.field-card-header {
    background-color: #e5e5e5;
  }

  &:nth-child(1) {
    margin-top: 4px;
  }

  .field-row {
    display: flex;
    flex-wrap: nowrap;
    font-size: .76rem;
    align-items: center;

    & > div {
      padding: 3px;
    }

    & > div {
      & > input {
        text-align: inherit;
        padding: 2px 4px;
        outline: none;
        width: 92%;
        height: 100%;
        border-bottom: 1px solid var(--v-success-base);
      }

      & > .input-error {
        border-bottom: 1px solid var(--v-error-base);
      }

      &:nth-child(1) {
        flex: 0 0 60px;
      }

      &:nth-child(2) {
        flex: 0 0 170px;
        overflow: hidden;
      }

      &:nth-child(3) {
        flex: 1;
      }

      &:nth-child(4) {
        flex: 0 0 150px;
      }

      &:nth-child(5) {
        flex: 0 0 70px;
      }

      &:nth-child(6) {
        flex: 0 0 60px;
      }

      &:nth-child(7) {
        flex: 0 0 30px;
      }
    }
  }

  .always-enable {
    pointer-events: all !important;
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important;
  }
}


.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.table-small-select {
  :deep(input) {
    line-height: 12px !important;
    padding: 0 !important;
  }

  :deep(.v-select__selection.v-select__selection--comma) {
    margin: 0 !important;
  }

  :deep(.v-input__icon) {
    width: 20px !important;
    height: 20px !important;
  }

  :deep(.v-input__control > .v-input__slot:before) {
    border-color: var(--v-success-base);
  }
}

.table-small-select.input-error {
  :deep(.v-input__control > .v-input__slot:before) {
    border-color: var(--v-error-base) !important;
  }
}

.post-filter {
  & > div {
    padding: 4px;

    & > input {
      text-align: inherit;
      padding: 2px 4px;
      outline: none;
      width: 92%;
      height: 100%;
      border-bottom: 1px solid var(--v-success-base);
    }

    & > .input-error {
      border-bottom: 1px solid var(--v-error-base);
    }
  }
}
.color-picker-open {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transition: border-radius 200ms ease-in-out;
}
.color-picker-close {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  transition: border-radius 200ms ease-in-out;
}

//.document-separator {
//  page-break-before: always;
//  margin-top: 0;
//  padding-top: 20px;
//  margin-bottom: 0 !important;
//  padding-bottom: 0 !important;
//}

//tbody {
//  page-break-inside: avoid;
//  page-break-after: always;
//  //margin-bottom: 20px;
//}

tr:has(td.table-page-separator) {
  page-break-before: always;
}

td {
  padding: 2px 2px 1px;
  vertical-align: top;
  font-size: small;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .td-image-wrapper {
    position: relative;

    .td-image {
      position: absolute;
      background-size: contain;
    }
  }
}

</style>